import Contact from './components/contact'
import Conversation from './components/conversation'
import Counselors from './components/counselors'
import CTA from "./components/cta"
import CTAChanger from "./components/ctaChanger"
import CTAChangerFunnel from "./components/ctaChangerFunnel"
import CTAContact from "./components/ctaContact"
import EmbedForm from "./components/embedForm"
import FlexibleContent from './components/flexibleContent'
import FlexPrograms from './components/flexPrograms'
import Form from './components/form'
import FourToSixPicker from './components/fourToSixPicker'
import FunnelGrid from './components/funnelGrid'
import Gallery from './components/gallery'
import GoogleMap from './components/googleMap'
import Grid from './components/grid'
import Hero from "./components/hero"
import ImageLink from './components/imageLink'
import Instructor from "./components/instructor"
import Jobs from "./components/jobs"
import JobsPost from "./components/jobsPost"
import Leadership from './components/leadership'
import AutryMap from "./components/map"
import OGPicker from './components/ogPicker'
import OneColumnText from './components/oneColumnText'
import Overview from './components/overview'
import PdfViewer from './components/pdfViewer'
import ProgramDetails from './components/programDetails'
import Programs from './components/programs'
import RoomDetails from './components/roomDetails'
import Rooms from './components/rooms'
import SafetySkills from './components/safetySkills'
import SessionBreakdown from './components/sessionBreakdown'
import ShortTermCourses from './components/shortTermCourses'
import ShortTermCoursesAll from './components/shortTermCoursesAll'
import Staff from './components/staff'
import Stats from './components/stats'
import StepsToApply from './components/stepsToApply'
import Table from './components/table'
import TabsTextImage from './components/tabsTextImage'
import TabsTwoColumns from './components/tabsTwoColumns'
import TenFunnels from './components/tenFunnels'
import Testimonials from './components/testimonials'
import TextImages from './components/textImages'
import TextLinksOneColumn from './components/textLinksOneColumn'
import TextMultipleLinks from './components/textMultipleLinks'
import TextVideoLinks from './components/textVideoLinks'
import TwoColumnText from './components/twoColumnText'
import Video from './components/video'
import Layout from './layout'

export default Layout
const Blocks: Record<string, any> = {
  'autry/contact': Contact,
  'autry/counselors': Counselors,
  'autry/cta': CTA,
  'autry/cta-changer': CTAChanger,
  'autry/cta-changer-funnel': CTAChangerFunnel,
  'autry/cta-contact': CTAContact,
  'autry/conversation': Conversation,
  'autry/embed-form': EmbedForm,
  'autry/flexible-content': FlexibleContent,
  'autry/flex-programs': FlexPrograms,
  'autry/form': Form,
  'autry/four-to-six-picker': FourToSixPicker,
  'autry/funnel-grid': FunnelGrid,
  'autry/gallery': Gallery,
  'autry/grid': Grid,
  'autry/google-map': GoogleMap,
  'autry/hero': Hero,
  'autry/image-link': ImageLink,
  'autry/instructor': Instructor,
  'autry/jobs': Jobs,
  'autry/jobs-post': JobsPost,
  'autry/leadership': Leadership,
  'autry/map': AutryMap,
  'autry/og-picker': OGPicker,
  'autry/one-column-text': OneColumnText,
  'autry/overview': Overview,
  'autry/pdf-viewer': PdfViewer,
  'autry/program-details': ProgramDetails,
  'autry/programs': Programs,
  'autry/rooms': Rooms,
  'autry/room-details': RoomDetails,
  'autry/safety-skills': SafetySkills,
  'autry/session-breakdown': SessionBreakdown,
  'autry/short-term-courses': ShortTermCourses,
  'autry/short-term-courses-all': ShortTermCoursesAll,
  'autry/staff': Staff,
  'autry/stats': Stats,
  'autry/steps-to-apply': StepsToApply,
  'autry/table': Table,
  'autry/tabs-text-image': TabsTextImage,
  'autry/tabs-two-columns': TabsTwoColumns,
  'autry/ten-funnels': TenFunnels,
  'autry/testimonials': Testimonials,
  'autry/text-images': TextImages,
  'autry/text-multiple-links': TextMultipleLinks,
  'autry/text-links-one-column': TextLinksOneColumn,
  'autry/text-video-links': TextVideoLinks,
  'autry/two-column-text': TwoColumnText,
  'autry/video': Video
}

export { Blocks }
