import React from 'react';

import styles from "./imageLink.module.scss";

type ImageLinkProps = {
  block: Record<string, any>;
};

const ImageLink = ({block}: ImageLinkProps) => {
  const {attrs: {image_link, image_url, image_alt_text}} = block ?? {}
  return (
    <section className={styles.imageLink}>
      <a href={image_link} target="_blank" rel="noopener noreferrer">
        <img className={styles.image} src={image_url} alt={image_alt_text ? image_alt_text : 'Alt text'} />
      </a>
    </section>
  )
}

export default ImageLink